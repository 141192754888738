import React, { useState, useEffect } from 'react';

import { POPUP_CLOSE } from './constants';

import './CampaignIframePopup.scss';

const CampaignIframePopup = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem(POPUP_CLOSE) !== 'true') {
      setIsOpen(true);
    }
  }, []);

  const handleClose = () => {
    setIsOpen(false);
    sessionStorage.setItem(POPUP_CLOSE, 'true');
  };

  return isOpen ? (
    <div className="iframe-popup">
      <div className="popup">
        <div className="popup-content">
          <iframe
            className="jebbit-iframe"
            src="https://reckitt.jebbit.com/delijoch?L=Full+Page&JC=finishpopup&deferred=true"
            frameborder="0"
            seamless
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
            style={{ width: '100%', minHeight: '600px' }}
            onload='function embedJebbit(t){function e(t){var e="attach"===t?window.addEventListener:window.removeEventListener;e("DOMContentLoaded",n,!1),e("load",n,!1),e("scroll",n,!1),e("resize",n,!1)}var i=document.querySelector(t);function n(){var t,e,n,o;e=(t=i).getBoundingClientRect(),n=t.clientHeight/2,o=t.clientWidth/2,e.top>=0&&e.left>=0&&e.top<=(window.innerHeight||document.documentElement.clientHeight)-n&&e.left<=(window.innerWidth||document.documentElement.clientWidth)-o&&i.contentWindow.postMessage("startJebbitCampaign","*")}window.addEventListener("message",function t(i){i&&"https://reckitt.jebbit.com"===i.origin&&("jebbitSessionCreated"===i.data?e("remove",n):"jebbitCampaignLoaded"===i.data?n():i.data&&"redirect"===i.data.action&&i.data.options&&i.data.options.link&&(window.location.href=i.data.options.link))},!1),e("attach")}embedJebbit(".jebbit-iframe")'
          />
          <button onClick={handleClose} className="close-modal">
            X
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default CampaignIframePopup;
